import React from 'react'

import logo_distribuidora from '../images/logo-distribuidora.png'
import imagem_distribuidora from '../images/distribuidora.jpg'
import logo_incorporadora from '../images/logo-incorporadora.png'
import imagem_incorporadora from '../images/incorporadora.jpg'
import logo_home from '../images/logo-home.png'
import imagem_home from '../images/home.jpg'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div style={{ overflow: 'hidden' }}>
      <div id="brands" className="hero">
        <div
          data-w-id="da904feb-2083-f977-3aac-8d7d3286d89f"
          className="hero-item distribuidora"
        >
          <div
            className="hero-background"
            style={{ backgroundImage: `url("${imagem_distribuidora}")` }}
          >
            <div className="hero-item-filter">
              <span className="info-title">Sou Lojista</span>
              <img
                src={logo_distribuidora}
                width="231"
                data-w-id="0e62167d-3850-fb2f-d6cf-e9eb13820f71"
                alt=""
                className="hero-logo"
              />
              <div
                data-w-id="71eb2b9f-46b8-9f1a-8f98-a321206ce09d"
                className="hero-button"
              >
                <a
                  href="https://www.goodsbr.com.br/catalogo/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button hero w-button"
                >
                  Acessar Site
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          data-w-id="677c3a9b-b2e9-c3cd-9a0a-008ce55b0367"
          className="hero-item incorporadora"
        >
          <div
            className="hero-background"
            style={{ backgroundImage: `url("${imagem_incorporadora}")` }}
          >
            <div className="hero-item-filter">
              <span
                className="info-title"
                style={{
                  fontSize: '18px',
                }}
              >
                Sou Investidor
              </span>
              <img
                src={logo_incorporadora}
                width="180"
                alt=""
                className="hero-logo"
              />
              <div className="hero-button">
                <a
                  href="https://empreendimentos.goodsbr.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button hero w-button"
                >
                  Acessar Site
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          data-w-id="532f77fc-5fd8-08bc-bddc-c3f348099b68"
          className="hero-item goodshome"
        >
          <div
            className="hero-background"
            style={{ backgroundImage: `url("${imagem_home}")` }}
          >
            <div className="hero-item-filter">
              <span
                className="info-title"
                style={{
                  fontSize: '18px',
                }}
              >
                Loja Conceito
              </span>
              <img src={logo_home} width="180" alt="" className="hero-logo" />
              <div className="hero-button">
                <a
                  href="https://www.google.com.br/maps/place/Av.+Borges+de+Medeiros,+2381+-+Centro,+Gramado+-+RS,+95670-000/@-29.3815166,-50.8743784,17z/data=!3m1!4b1!4m5!3m4!1s0x951932447b131b4b:0x40907ea21cd26ab7!8m2!3d-29.3815213!4d-50.8721897"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button hero w-button"
                >
                  Acessar Localização
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
